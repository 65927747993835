<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="@/assets/back.png"
            alt="홈으로"
            type="button"
            @click="$router.replace('/')"
          >
          <span class="config-title">&nbsp;&nbsp;&nbsp;관리자 설정</span>
        </v-row>
        <v-row
          class="setting-area"
          justify="center"
        >
          <v-col
            align-self="center"
            cols="12"
            md="6"
          >
            <v-btn
              class="btn mt-9"
              height="130"
              width="100%"
              color="#00BFB4"
              dark
              @click="$router.replace('/admin-setting')"
            >
              옵션 관리
            </v-btn>
            <br>
            <v-btn
              class="btn mt-9"
              height="130"
              width="100%"
              color="#00BFB4"
              dark
              @click="$router.replace('/admin-testing')"
            >
              API 테스트
            </v-btn>
            <br>
            <v-btn
              class="btn mt-9"
              height="130"
              width="100%"
              color="#00BFB4"
              dark
              @click="$router.replace('/admin-calibration')"
            >
              구동제어 변수교정
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AdminConfig',
}
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.container > .header {
  top: 0;
}

.container > .header > .config-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
}

.setting-area {
  height: 70vh;
  overflow: auto;
}

.btn {
  overflow: auto;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
  border-radius: 10px;
}

@media (max-width: 1485px) {
  .header img {
    width: 50px;
    height: 50px;
  }

  .btn {
    font-size: 40px !important;
  }

  .container > .header > .config-title {
    font-size: 40px !important;
  }
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .container > .header > .config-title {
    font-size: 20px !important;
  }

  .btn {
    height: 50px !important;
    font-size: 20px !important;
  }
}
</style>
